dmx.Attribute("on", "mounted", function (node, attr) {
  if (!node.dmxOn) {
    node.dmxOn = {
      component: this,
    };
  }

  node.dmxOn[attr.argument] = true;

  dmx.eventListener(node, attr.argument, function (event) {
    // jQuery event
    if (event.originalEvent) event = event.originalEvent;

    const retValue = dmx.parse(attr.value, dmx.DataScope({
      $event: event.$data,
      $originalEvent: event,
    }, node.dmxOn.component));

    return retValue;
  }, attr.modifiers);
});
